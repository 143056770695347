

  export default {
    props: {
      payload: Object
    },
    computed: {
      // institutionsSorted() {
      //   const array =  this.payload.sort((a, b) => {
      //     if (a.name < b.name) return -1
      //     return a.name > b.name ? 1 : 0
      //   })
      //   return array
      // }
    }
  }

